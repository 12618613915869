<template>
    <div class="execution-page">
        <div
            class="content-container"
            v-if="
                Object.keys(executionAllTask).length > 0 &&
                    Object.keys(executionCurrentStatus).length > 0
            "
        >
            <router-view
                :executionAllTask="executionAllTask"
                :executionCurrentStatus="executionCurrentStatus"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import ExecutionPageModule from 'store/modules/executionPage'

export default {
    name: 'ExecutionPage',
    components: {},
    mixins: [RegisterStoreModule],
    data() {
        return {}
    },
    computed: {
        ...mapState({
            executionCurrentStatus: state =>
                state.ExecutionPage.executionCurrentStatus,
            executionAllTask: state => state.ExecutionPage.executionAllTask,
        }),
    },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'SILOHO',
        // all titles will be injected into this template
        titleTemplate: '%s | Execution',
        bodyAttrs: {
            class: ['execution-page-body'],
        },
    },
    created() {
        this.registerStoreModule('ExecutionPage', ExecutionPageModule)
        this.fetchExecutionTracker(this.$route.params.id)
        this.fetchExecutionAllTask(this.$route.params.id)
    },
    destroyed() {
        // this.$store.unregisterModule('ExecutionPage')
    },
    methods: {
        ...mapActions({
            fetchExecutionTracker: 'fetchExecutionTracker',
            fetchExecutionAllTask: 'fetchExecutionAllTask',
        }),
    },
}
</script>

<style lang="scss">
@import './ExecutionPage.scss';
</style>
