import { getExecutionTracker, getExecutionAllTask } from 'api/orderApi'


const OrderDetailsPage = {
    state: () => ({
        executionCurrentStatus:{},
        executionAllTask:{}
    }),

    mutations: {
        setExecutionCurrentStatus(state, payload){
            state.executionCurrentStatus = payload
        },
        setExecutionAllTask(state, payload){
            state.executionAllTask = payload
        },
    },

    getters: {},

    actions: {
        fetchExecutionTracker({commit},id){
            return getExecutionTracker(id)
                .then(data => {
                    if (data.responseCode === 404) {
                        console.log(data.responseMessage)
                    } else {
                        commit('setExecutionCurrentStatus', data.payloads)
                        return data
                    }
                })
        },
        fetchExecutionAllTask({commit},id){
            return getExecutionAllTask(id)
                .then(data => {
                    if (data.responseCode === 404) {
                        console.log(data.responseMessage)
                    } else {
                        commit('setExecutionAllTask', data.payloads)
                        return data
                    }
                })
        },
    }
}

export default OrderDetailsPage;